<template>
  <div>
    <Pane />
<a-modal :visible="dialogShow" title="编辑" :footer="null" @cancel="dialogShow = false" width="500px">
  <a-form
          :form="form"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 12 }"
          :colon="false"
        >
          <a-form-item
              label="出发地"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-cascader
                change-on-select
                placeholder
                v-model="departureId"
                :options="bcCityList"
                :load-data="loadData"
                :fieldNames="{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }"
              />
            </a-form-item>
          <a-form-item
              label="目的地"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-cascader
                change-on-select
                placeholder
                v-model="targetAddrId"
                :options="bcCityList"
                :load-data="loadData"
                :fieldNames="{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }"
              />
            </a-form-item>
        </a-form>
        <div class="right">
          <a-space>
            <a-button @click="dialogShow = false">取消</a-button>
            <a-button type="primary" @click="handleSubmit">提交</a-button>
          </a-space>
        </div>
  </a-modal>
    <a-card class="container">
          <a-row>
            <a-col :span="22">
              <a-form-model :colon="false" :model="form" layout="inline" @keyup.enter.native="query">
                <a-form-model-item>
                  <a-select style="width: 160px" v-model="year">
                    <a-select-option
                      v-for="item in [
                      {
                          name: '2025',
                          value: 2025,
                      },
                      {
                          name: '2024',
                          value: 2024,
                      }]"
                      :key="item.value"
                      :value="item.value"
                    >{{ item.name }}</a-select-option>
                  </a-select>
                </a-form-model-item>

                <a-form-model-item>
                  <a-space>
                    <a-button @click="query" type="primary">查询</a-button>
                    <a-button @click="reset">重置</a-button>
                  </a-space>
                </a-form-model-item>
              </a-form-model>
            </a-col>
          </a-row>

        <Padding />

        <a-table
          bordered
          :data-source="list"
          :loading="loading"
          :pagination="false"
          rowKey="id"
        >
          <a-table-column title="部门" data-index="deptName"></a-table-column>
          <a-table-column title="姓名" data-index="name" align="center"></a-table-column>

          <a-table-column title="时长" align="center">
            <template slot-scope="text">
              {{ text.duration }}天
            </template>
          </a-table-column>
          <a-table-column title="开始时间" data-index="startTime" align="center"></a-table-column>
          <a-table-column title="结束时间" data-index="endTime" align="center"></a-table-column>
          <a-table-column title="出发地" data-index="departure" align="center"></a-table-column>
          <a-table-column title="目的地" data-index="targetAddr" align="center"></a-table-column>
          <a-table-column title="同步状态" align="center">
            <template slot-scope="text">
              <DataDictFinder
              :title="text.syncStatus == 99 || text.syncStatus == 199 ? 'syncErrorMsg' : ''"
              dictType="att_beisen_sync_status"
              :dictValue="text.syncStatus + ''"
            />
            </template>
          </a-table-column>
          <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                v-if="text.syncStatus == null"
                @click.prevent="edit(text)"
                >编辑</a
              >
            </a-space>
          </template>
        </a-table-column>
        </a-table>

        <div style="margin: 40px 0;"><span style="color: red">出差天数必须大于一天</span></div>
    </a-card>
  </div>
</template>
    
<script>
import { fetchLeaveRecord,editLeaveRecord } from "@/api/human-resources/clock";
import moment from "moment";
import { downloadByHtml } from "@/utils/xlsx";
import { fetchBsList } from '@/api/city'
export default {
  data() {
    return {
      form: {
        date: [moment(), moment()]
      },

      loading: false,
      year: 2025,
      list: [],
      dialogShow:false,
      targetAddrId: '',
      departureId: '',
      id: '',
      bcCityList: [],
    };
  },

  mounted() {
    this.getList();
    fetchBsList({pid: '-1'}).then(res => {
        if (Array.isArray(res)) {
            this.bcCityList = res
            console.log(res)
        }
    })
  },

  methods: {
    loadData(selectedOptions) {
      console.log(selectedOptions)
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      console.log(selectedOptions.id);
      fetchBsList({pid: targetOption.id}).then(res => {
        if (Array.isArray(res)) {
            targetOption.loading = false;
            targetOption.children = res;
            this.bcCityList = [...this.bcCityList];
        }
    })
    },
    handleSubmit(e) {
      e.preventDefault();
      console.log(this.departureId);
      editLeaveRecord({
        id: this.id,
        departureId: this.departureId[this.departureId.length - 1],
        targetAddrId: this.targetAddrId[this.targetAddrId.length - 1],
      })
        .then(res => {
          this.dialogShow = false;
          this.getList();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getList() {
      this.loading = true;
      fetchLeaveRecord({
        year: this.year,
      })
        .then(res => {
          console.log(res);
          if (Array.isArray(res)) {
            this.list = Object.freeze(res);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    query() {
      this.current = 1;
      this.getList();
    },
    edit(text) {
      this.dialogShow = true
      this.id = text.id;
      this.targetAddrId = [text.targetAddrId];
      this.departureId = [text.departureId];
    },

    reset() {
      this.form = {
        date: [moment(), moment()]
      };
      this.current = 1;
      this.getList();
    },
    download() {
      downloadByHtml(document.getElementsByTagName("table")[0], "出入统计");
    }
  }
};
</script>