var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-modal',{attrs:{"visible":_vm.dialogShow,"title":"编辑","footer":null,"width":"500px"},on:{"cancel":function($event){_vm.dialogShow = false}}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false}},[_c('a-form-item',{attrs:{"label":"出发地","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-cascader',{attrs:{"change-on-select":"","placeholder":"","options":_vm.bcCityList,"load-data":_vm.loadData,"fieldNames":{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }},model:{value:(_vm.departureId),callback:function ($$v) {_vm.departureId=$$v},expression:"departureId"}})],1),_c('a-form-item',{attrs:{"label":"目的地","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-cascader',{attrs:{"change-on-select":"","placeholder":"","options":_vm.bcCityList,"load-data":_vm.loadData,"fieldNames":{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }},model:{value:(_vm.targetAddrId),callback:function ($$v) {_vm.targetAddrId=$$v},expression:"targetAddrId"}})],1)],1),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":function($event){_vm.dialogShow = false}}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("提交")])],1)],1)],1),_c('a-card',{staticClass:"container"},[_c('a-row',[_c('a-col',{attrs:{"span":22}},[_c('a-form-model',{attrs:{"colon":false,"model":_vm.form,"layout":"inline"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.query.apply(null, arguments)}}},[_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"160px"},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}},_vm._l(([
                      {
                          name: '2025',
                          value: 2025,
                      },
                      {
                          name: '2024',
                          value: 2024,
                      }]),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-model-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.query}},[_vm._v("查询")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1)],1)],1)],1),_c('Padding'),_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"loading":_vm.loading,"pagination":false,"rowKey":"id"}},[_c('a-table-column',{attrs:{"title":"部门","data-index":"deptName"}}),_c('a-table-column',{attrs:{"title":"姓名","data-index":"name","align":"center"}}),_c('a-table-column',{attrs:{"title":"时长","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_vm._v(" "+_vm._s(text.duration)+"天 ")]}}])}),_c('a-table-column',{attrs:{"title":"开始时间","data-index":"startTime","align":"center"}}),_c('a-table-column',{attrs:{"title":"结束时间","data-index":"endTime","align":"center"}}),_c('a-table-column',{attrs:{"title":"出发地","data-index":"departure","align":"center"}}),_c('a-table-column',{attrs:{"title":"目的地","data-index":"targetAddr","align":"center"}}),_c('a-table-column',{attrs:{"title":"同步状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"title":text.syncStatus == 99 || text.syncStatus == 199 ? 'syncErrorMsg' : '',"dictType":"att_beisen_sync_status","dictValue":text.syncStatus + ''}})]}}])}),_c('a-table-column',{attrs:{"title":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a-space',[(text.syncStatus == null)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.edit(text)}}},[_vm._v("编辑")]):_vm._e()])]}}])})],1),_c('div',{staticStyle:{"margin":"40px 0"}},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("出差天数必须大于一天")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }