import request from '../request'

// 获取省市区
export function fetchList() {
    return request({
        url: '/common-service/district/queryDistrictList',
    })
}

// 获取北森
export function fetchBsList(data) {
    return request({
        url: '/office-service/beiSen/sync/query/areaByPid',
        method: 'post',
        data
    })
}

